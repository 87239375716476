import React from "react";
import { withTranslation } from "react-i18next";
import SocialShare from "../SocialShare"
import Heading from "../Heading"
import './rules.scss'
class Rules extends React.Component {

    render() {

        const { data, content, t } = this.props
        return(
            <div id="rules" class="blog-area blog-no-sidebar pt-100 pb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 blog-post-item ml-auto mr-auto">
                            <div class="blog-wrapper blog-details">
                                <div class="blog-content">
                                <Heading title={data.pageTitle} subtitle={data.pageSubtitle} className="mb-50" />
                                    <div dangerouslySetInnerHTML={{__html: content}} />
                                </div>
                            </div>
                            <div class="common-tag-and-next-prev mt-60">
                                <SocialShare title={data.title} hashtags="tiliana-rules" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Rules)